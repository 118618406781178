
  import {Component, Ref, Vue} from "vue-property-decorator";
  import {
    AttachmentHostType,
    AuditFlowScope,
    CloseReportDetailDto,
    OrganizationUnitDto,
    SummaryReportDetailDto
  } from "@/api/appService";
  import api from "@/api";
  import moment from "moment";
  import ExportWord from "@/components/DownloadWord/index.vue";
  import {AuditBodyDto} from "@/utils/customDto";
  import AuditButtons from "@/components/AuditButtons/index.vue";
  import AttachmentsView from "@/components/AttachmentsView/index.vue";

  @Component({
    name: "progressReportDetail",
    components: {
      ExportWord,
      AuditButtons,
      AttachmentsView
    },
    filters: {
      formatBudgetCategory(budgetCategory: string, projectBudgetCategoryList: any[]) {
        let budget: string | undefined
        if (projectBudgetCategoryList && projectBudgetCategoryList.length > 0) {
          projectBudgetCategoryList.map((item: any) => {
            if (item.value == budgetCategory) {
              budget = item.text
              return
            }
          })
        }
        return budget
      }
    }
  })
  export default class ProgressReportDetail extends Vue {
    @Ref() auditButtons!: AuditButtons;
    dataId?: number;
    detail: CloseReportDetailDto = {};
    selector: string = "world-content";
    filename: string = "结项评估总结报告";
    styles: string = "";
    hostType = AttachmentHostType.External_Project_Close_Report;
    auditParams: AuditBodyDto = {
      hostId: undefined,
      hostType: AuditFlowScope.ExternalProjectCloseReport,
      auditFlowId: undefined,
      auditStatus: undefined,
      audit: undefined
    }
    projectId?: undefined | number;
    summaryReportDetail: SummaryReportDetailDto = {};
    // 项目预算种类 一级
    projectBudgetCategoryList = [];

    // 项目预算种类 二级
    projectBudgetTypeList = [];

    // 资金来源
    projectBudgetCapitalSourceList = [];

    created() {
      if (this.$route.params.id) {
        this.dataId = Number(this.$route.params.id);
        this.fetchDetail();
      }
      api.enumService.getValues({typeName: 'ProjectBudgetCategory'}).then((res: any) => {
        this.projectBudgetCategoryList = res
      });
      api.enumService.getValues({typeName: 'ProjectBudgetType'}).then((res: any) => {
        this.projectBudgetTypeList = res
      });
      api.enumService.getValues({typeName: 'ProjectBudgetCapitalSource'}).then((res: any) => {
        this.$nextTick(() => {
          this.projectBudgetCapitalSourceList = res
        })
      });
      // api.dataDictionary.getDataDictionaryListByKey({key: 'EducationType'}).then((res:any) => {
      //   this.educationList = res.items
      // })
    }
    get getBudgetSun() {
      let num = 0
      if(this.summaryReportDetail.externalProjectActualBudgetItems && this.summaryReportDetail.externalProjectActualBudgetItems.length>0) {
        this.summaryReportDetail.externalProjectActualBudgetItems.map(item=>{
          num += Number(item.budgetQuotation)
        })
      }
      return num
    }
    // 获取详情
    async fetchDetail() {
      await api.externalProjectCloseReport.getDetail({id: this.dataId}).then(res => {
        this.detail = {...res};

        this.auditParams!.hostId = res.id + ''
        this.auditParams!.auditFlowId = res.auditFlowId;
        this.auditParams!.auditStatus = res.auditStatus;
        this.auditParams!.audit = res.audit;
        if (res.auditFlowId) {
          (this.auditButtons as any).fetchCanAudit()
        }
        this.projectId = res.projectId;
        this.fetchDetailByProjectId()
      });
    }

    // 获取自评详情

    async fetchDetailByProjectId() {
      await api.externalProjectSummaryReport.getDetailByProjectId({projectId: this.projectId}).then((res: SummaryReportDetailDto) => {
        if (res) {
          this.summaryReportDetail = res;
        }
      })
    }

    // 返回
    private cancel() {
      this.$router.back();
    }

    fetchData() {
      return api.externalProjectCloseReport.audit;
    }

    changeAudit() {
      this.fetchDetail()
    }
  }
